<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>通知公告详情</span></div>
    </div>
    <div class="container">
      <h1 class="title">{{ noticeDetail.title }}</h1>
      <div class="row">
        <div v-html="noticeDetail.content"></div>
      </div>
    </div>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "@/components/footer.vue";
import banner2 from "@/components/banner.vue";
import nav2 from "@/components/nav.vue";
import article from "@/api/article";
export default {
  name: "NoticeDetail",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "通知公告详情",
      Img1: require("../assets/images/newsbanner.jpg"),
      noticeDetail: [],
    };
  },
  created() {
    if (this.$route.query) {
      this.infoParams = this.$route.query;
      // console.log("infoParams", this.infoParams);
      let id = this.$route.query.id;
      this.getList(id);
    }
  },
  methods: {
    getList(id) {
      article.getNoticeDetail({ id: id }).then((res) => {
        // console.log(res, "333");
        this.noticeDetail = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
.container {
  width: 1100px;
  margin: 0 auto;
  height: 500px;
}
.container h1 {
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #868686;
  margin-bottom: 20px;
}

.row {
  padding-top: 20px;
}
</style>
