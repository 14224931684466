<template>
  <div class="baochen-table-inof">
    <el-table
      v-loading="loading"
      :data="caigoutableList"
      height="500"
      @row-click="goInfo"
      style="cursor: pointer"
    >
      <el-table-column label="公告" align="left" prop="title" />
      <el-table-column
        label="采购单位"
        align="left"
        prop="author"
        width="250"
      />
      <el-table-column
        label="发布日期"
        align="left"
        prop="publishDate"
        width="180"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.publishDate.slice(0, 11) }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="开标时间"
        align="center"
        prop="bidOpeningTime"
        width="180"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.bidOpeningTime.slice(0, 11) }}</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column
        label="截至日期"
        align="center"
        prop="invalidDate"
        width="180"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.invalidDate.slice(0, 11) }}</span>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.current"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="pagination.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import { caiGouList,caiGouTableList,listCaigoutable } from '@/api/system/caigoutable'
// import { caiGouTableList } from '@/api/system/caigoulist'
// import axios from "axios";
import article from "@/api/article";
export default {
  name: "CaiTable",
  props: {
    searchString: {
      type: String,
      default: () => {},
    },
  },
  watch: {
    searchString: {
      handler(newData) {
        // console.log("oldData",oldData)
        // console.log("newData",newData)
        // if(newData != null && newData !== ''){
        // this.queryParams.title = newData;
        this.getList(newData);
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 政府采购意向表格数据
      caigoutableList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,

      // 表单校验
      rules: {},
      pagination: {
        total: 100,
        size: 100,
        current: 1,
      },
      queryParams: {
        pageNum: 1,
        pageSize: 100,
        articleId: null,
        annId: null,
        siteId: null,
        firstCode: null,
        parentId: null,
        secondCode: null,
        author: null,
        cover: null,
        path: null,
        pathName: null,
        title: null,
        content: null,
        publishDate: null,
        districtCode: null,
        gpCatalogCode: null,
        gpCatalogName: null,
        procurementMethodCode: null,
        procurementMethod: null,
        bidOpeningTime: null,
        projectCode: null,
        projectName: null,
        districtName: null,
        districtNameList: null,
        purchaseName: null,
        rankcategoryName: null,
        encryptId: null,
        invalid: null,
        invalidDate: null,
        isRenew: null,
        announcementType: null,
        ownerShotDepartmentName: null,
        infoCard: null,
        tenantId: null,
        createUser: null,
        createDept: null,
        updateUser: null,
        status: null,
        isDeleted: null,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询政府采购意向列表 */
    getList(titleText) {
      this.loading = true;
      let params = {
        size: this.pagination.size,
        current: this.pagination.current,
        title: titleText,
      };
      article
        .getcaiTable(params)
        .then((res) => {
          let result = res.data.data;
          // console.log(res.data.data, "<<<<<<<<");
          this.pagination.total = result.total;
          this.pagination.size = result.size;
          this.pagination.current = result.current;
          this.caigoutableList = res.data.data.records;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSizeChange(val) {
      this.pagination.size = val;
      // console.log(`每页 ${val} 条`);
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.current = val;
      // console.log(`当前页: ${val}`);
      this.getList();
    },
    goInfo(row) {
      var articleId = row.articleId;
      var url = this.$router.resolve({
        path: "/tableinfo",
        query: { articleId },
      }).href;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 30px;
  text-align: right;
}
</style>
