<template>
  <div>
    <div>
      <!-- header -->
      <div class="breadcrumbs-wrapper">
        <banner2 :bannerImg="Img1"></banner2>
        <nav2></nav2>
        <!-- 面包屑 -->
        <div class="Crumbs">
          <div>当前位置：首页><span>政府采购公告详情</span></div>
        </div>
        <!-- <ul class="breadcrumbs" style="">
        <li><router-link class="home" to="/home"><i class="fa fa-home" aria-hidden="true"></i></router-link></li>
        <li><a>政府采购公告详情</a></li>
      </ul> -->
      </div>
      <!-- breadcrumbs-wrapper -->
      <section class="content-wrapper">
        <div class="container" v-loading="loading">
          <div style="margin-bottom: 20px" class="main">
            <h1>{{ caigoutable.title }}</h1>
            <div class="source">
              <span>来源：{{ caigoutable.author }}</span>
              <span>发布时间{{ caigoutable.publishDate }}</span>
            </div>
          </div>
          <div class="row">
            <div v-html="caigoutable.content"></div>
          </div>
          <div>
            广西政府采购网官方公告：<a :href="govHttpAddress" target="_blank"
              >原文地址</a
            >
          </div>
          <!--        <div class="map"></div>-->
        </div>
      </section>

      <!-- <section id="team"></section> -->
    </div>
    <Footer></Footer>
    <!--  {{caigoutable.content}}-->
  </div>
</template>

<script>
import banner2 from "@/components/banner.vue";
import Footer from "@/components/footer.vue";
import nav2 from "@/components/nav.vue";
import article from "@/api/article";
export default {
  name: "tableinfo",
  components: {
    Footer,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "公告详情",
      Img1: require("@/assets/images/banner1.png"),
      p1: "更多最新精彩资讯，感受企业魅力",
      infoParams: null,
      caigoutable: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        articleId: null,
      },
      govHttpAddress: "",
    };
  },
  created() {
    if (this.$route.query) {
      this.infoParams = this.$route.query;
      // console.log("infoParams", this.infoParams);
      let articleId = this.$route.query.articleId;
      this.getList(articleId);
    }
  },
  methods: {
    getList(articleId) {
      this.loading = true;
      article.getcaiTableDetail({ articleId: articleId }).then((res) => {
        this.caigoutable = res.data.data;
        var temptag =
          "http://zfcg.gxzf.gov.cn/site/detail?parentId=66485&articleId=" +
          this.caigoutable.articleId +
          "&utm=site.site-PC-38919.1024-pc-wsg-secondLevelPage-front.1.5320e5d0c37c11ee8334dd6d4134a42f";
        this.govHttpAddress = temptag;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

.content-wrapper {
  width: 1100px;
  margin: 0 auto;
}
.main {
  border-bottom: 1px dotted #000;
  padding-bottom: 20px;
}
.main h1 {
  text-align: center;
  padding: 30px 0;
}
.main .source {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  color: #333;
}
.row {
  margin-top: 30px;
}
a {
  color: #3494ec;
}
</style>
